
import Vue from "vue";
import router from "../../router";
import API_connector from "@/utils/connector";
import settings from "@/assets/json/settings.json"

const state = {

  api: new API_connector(),

  path:     "https://api.zakaz.cloud",
  path_dev: "http://dev.api.zakaz.cloud",

  default_user:{

    username:       "api_user@zakaz.cloud",
    password:       "EubMWqYVyT3W3QFFo6b3JVQCk4wLmwo",
    isAdmin:        false,
    theater_select: 0,        // только глобальные вещи, без подгрузки какого-либо театра
    isGlobal:       true,     // в какой токен писать результаты авторизации

  },

  global_user:{
    token:    localStorage.getItem("global-token") || "",
    status:   "none",
    user:{}, //? надо вообще
  },

  user:{
    token:    localStorage.getItem("user-token") || "",
    theater:  0,
    status:   "none",
    domain:   "",
    isDefaultUser: true
  },





  profile_info:{

    isLoaded:     false,
    isGlobalUser: false,
    menu:         'none',

    user:{

      id:    0,
      role_id:    0,
      email:      "mail@mail.ru",
      login_time: "15 июня 20-49",

      role_name:  "роль",
      role_color: "red",
      foto:       "https://cdn.vuetifyjs.com/images/john.jpg",

      token:      localStorage.getItem("user-token") || "",

    },

    monger:{

      id:  0,

      family:     "Фамилия",
      name:       "Имя",
      fname:      "Отчестов",

      mark:       "FIO",
      phone:      "+7",

    },

    playhouse:{

      id: 0,
      name: "Театр",
      path: "",
      show:{
        coupon:false
      }
    },

  },

  order: {
    user_id:0,
    event_id:0,
    culture_id:0,
    seats:[],

    email:'',
    phone:'',
    fio:'',

    family:'',
    name:'',
    f_name:'',

    link: false,

    payment_type: 0,

    total:{
      count:0,
      price:0
    },

    show_name:'Спектакль',
    date: '0',

  },
};

const getters = {

  isGlobalAuthenticated:   state => !!state.global_user.token,
  //isUserAPIAuthenticated:  state => !!state.user.token,
  isUserAuthenticated:     state => !!state.user.token,
  getProfileInfo:   state => state.profile_info,

  getOrder:         state => state.order,

  // getToken и в connector ссылаться на него - флаги еще глобальный или локальный

};

const actions = {

  loginInit:({dispatch, commit, getters})=>{

    //console.log('loginInit action');

    commit("setConfigLoadMessage", "Начали глобальный вход");

    // глобальный
    if (getters.isGlobalAuthenticated){
      commit("setConfigLoadMessage", "Глобальный токен подгружен");
      commit("setConfigLoadStatus", {module: "authGlobal", value: true});
      //console.log('global_token_exist');
    }
    else{
      //console.log('no global token');

      commit("setConfigLoadMessage", "Входим глобальным пользователем");
      dispatch("globalLogin")
          .then(()=>{
            //console.log('auth global is ready');

            commit("setConfigLoadMessage", "Глобальный токен получен");
            commit("setConfigLoadStatus", {module: "authGlobal", value: true});
          });
    }



    // локальный вход
    commit("setConfigLoadMessage", "Ищем локального пользователя");

    if (getters.isUserAuthenticated){
      commit("setConfigLoadMessage", "Загружаем локальный профиль");

      //console.log('user_token_exist');

      // сделать user/me
      dispatch('getUserMe')
          .then(()=>{
            //console.log('user me after');
            commit("setConfigLoadMessage", "Загрузили локального пользователя");
            commit("setConfigLoadStatus", {module: "authLocal", value: true});
          });
    }
    else{
      //console.log('no user_token_exist');

      // смотрим в каком мы домене / театре
      //console.log(getters.getPlayhouse);

      if (getters.getPlayhouse.isGlobal){
        //console.log('global no need API');
        dispatch('loadMenuUserGlobal');
      }
      else{
        //console.log('local start get userAPI');
        dispatch("localAPILogin", {
          theater : getters.getPlayhouse.id
        })
            .then(()=>{
              console.warn('auth API user is ready');

              state.api.initToken();
              commit("setConfigLoadStatus", {module: "authLocal", value: true});

            });
      }

    }

  },



  globalLogin:({dispatch, state}) =>{

    //console.log('11');
    return dispatch("auth", state.default_user);

  },

  // eslint-disable-next-line no-unused-vars
  localAPILogin:({dispatch, state}, data) =>{

    let credentials = {};
    Object.assign(credentials, state.default_user);

    credentials.theater_select = data.theater;
    credentials.isGlobal = false;

    return dispatch("auth", credentials);

  },

  localLogin:({dispatch, state}, data) =>{

    let credentials = state.default_user;

    credentials.username = data.username;
    credentials.password = data.password;
    credentials.theater_select = data.theater;
    credentials.isGlobal = false;
    credentials.isAdmin = false;

    return dispatch("auth", credentials);

  },




  getUserMe:({commit, dispatch}) =>{

    // поставить статус грузимся

    //
    state.api.hello();

    // достать из апи инфу о зрителе
    return state.api.getUserMe()
        .then(resp=>{

          //console.log('get_user_me');
          //console.log(resp);

          if (resp.email==="api_user@zakaz.cloud"){
            commit("setConfigLoadStatus", {module: "authLocalAPI", value: true});
            dispatch('loadMenuUserAPI');
          }
          else{
            commit("setConfigLoadStatus", {module: "authLocalUser", value: true});
            dispatch('loadMenuUserLocal');
          }

          commit('setUserInfo', resp);
          //dispatch("loadMenu");

          return resp;

        })
        .catch(err => {
          console.log('errr');
          console.log(err);
        });

  },

  auth({commit, dispatch, state}, data){

    //console.log('auth action');

    if (data.isGlobal){
      console.log('global');
      commit('setGlobalUserStatus', 'begin');
    }
    else{
      commit('setLocalUserStatus', 'begin');
    }

    //console.log(data);

    let t = state.api.auth(data)
        .then(resp=>{

          console.log('auth user');

          //console.log(data);
          //console.log(resp);

          if (resp.code !== 400){

            if (data.isGlobal){
              // TODO раздаем глобальные куки

              localStorage.setItem("global-token", resp.token);
              commit('authGlobalGood', resp);
              commit('setGlobalUserStatus', 'ready');
              //console.log('auth global ready - user.js');
            }
            else{
              localStorage.setItem("user-token", resp.token);
              commit('authGood', resp);
              commit('setLocalUserStatus', 'ready');
              return dispatch('getUserMe');
            }
          }
          else{
            // проверить для всех ли случаев подходит - глобально/локально
            commit('authBad', resp);
          }
        })
        .catch(err => {
          console.log('errr');
          console.log(err);
        });

    //console.log('auth complete');

    return t;

  },

  logout:({commit}) =>{

    console.log('exit action');

    let profile_reset = {

      local_user:    false,
      confirmed:     false,
      menu:          "",

      user:{
        id:    0,
        role_id:    0,
        email:      "",
        login_time: "",

        role_name:  "",
        role_color: "grey",
        foto:       "",
      },

      profile:{
        id:  0,
        family:     "",
        name:       "",
        fname:      "",
      },

      place: 0,
      place_name:"Театр",
      path:"#",
    };

    commit('setUserInfo', profile_reset);

    localStorage.removeItem("user-token");
    commit('setDefaultNAMenu');

    router.push("/");

    console.log('exit complete');
  },


};

const mutations = {

  setGlobalUserStatus:(state, status) =>{

    state.global_user.status = status;

  },

  setLocalUserStatus:(state, status) =>{

    state.user.status = status;

  },

  authGlobalGood:(state, data) =>{

    //console.log('authGlobalGood');

    state.global_user.token = data.token;

  },

  authGood:(state, data) =>{

    //console.log('authGood');

    state.user.token = data.token;
    state.profile_info.user.token = data.token;

  },

  authBad:(state, data) =>{

    console.log('auth bad');
    state.profile_info.user.token = "";

    console.log(data);

  },


  setUserInfo: (state, payload) =>{

    //console.log('set user info');

    // user base
    Vue.set(state.profile_info.user, "id",          payload.id);
    Vue.set(state.profile_info.user, "role_id",     payload.role);
    Vue.set(state.profile_info.user, "email",       payload.email);
    Vue.set(state.profile_info.user, "login_time",  payload.time);

    let role_name   = "роль не определена";
    let role_color  = 'dark';
    let mark        = "U";

    switch (payload.role){
      case 100:
        role_name = "Мастер";
        role_color = "deep-purple";
        mark = 'M';
        break;

      case 50:
        role_name = "Администратор";
        role_color = "red";
        mark = 'A';
        break;

      case 30:
        role_name = "Кассир";
        role_color = "orange";
        mark = 'K';
        break;

      case 1:
        role_name = "Зритель";
        role_color = "green";
        mark = "З";
        break;

      default:
        role_name = "Некто";
        role_color = "lime";
        mark = 'Х';
        break;
    }

    Vue.set(state.profile_info.user, "role_name",   role_name);
    Vue.set(state.profile_info.user, "role_color",  role_color);
    Vue.set(state.profile_info.user, "foto",  0);

    // monger
    Vue.set(state.profile_info.monger, "id", 0);

    Vue.set(state.profile_info.monger, "family", payload.profile.family);
    Vue.set(state.profile_info.monger, "name",   payload.profile.name);
    Vue.set(state.profile_info.monger, "fname",  payload.profile.fname);

    Vue.set(state.profile_info.monger, "mark",  mark);
    Vue.set(state.profile_info.monger, "phone", 0);

    // playhouse
    Vue.set(state.profile_info.playhouse, "id",     payload.place);
    Vue.set(state.profile_info.playhouse, "name",   payload.place_name);
    Vue.set(state.profile_info.playhouse, "path",   payload.path);


    let foundPh = settings.playhouses.find(el=>el.id === payload.place)
    if(foundPh) Vue.set(state.profile_info.playhouse,"show",foundPh.show)

    // common
    Vue.set(state.profile_info, "menu",           payload.menu);
    Vue.set(state.profile_info, "isGlobalUser",   !payload.local_user);
    Vue.set(state.profile_info, "isLoaded",       payload.confirmed);

    //console.log('ready user info');

  },




  updateOrderUserData:(state, data) =>{

    Vue.set(state.order, "email", data.email);
    Vue.set(state.order, "phone", data.phone);

    Vue.set(state.order, "family", data.family);
    Vue.set(state.order, "name", data.name);
    Vue.set(state.order, "f_name", data.f_name);

    Vue.set(state.order, "fio", data.family+' '+data.name+' '+data.f_name);


  },

  updateOrder: (state, data) =>{

    //console.log(data);
    Vue.set(state.order, "user_id", data.user_id);
    Vue.set(state.order, "event_id", data.event_id);
    Vue.set(state.order, "culture_id", data.culture_id);
    Vue.set(state.order, "email", data.email);
    Vue.set(state.order, "phone", data.phone);
    Vue.set(state.order, "fio", data.fio);
    Vue.set(state.order, "family", data.family);
    Vue.set(state.order, "name", data.name);
    Vue.set(state.order, "f_name", data.f_name);
    Vue.set(state.order, "link", data.link);
    Vue.set(state.order, "payment_type", data.payment_type);
    Vue.set(state.order, "show_name", data.show_name);

    Vue.set(state.order, "seats", data.seats);
    Vue.set(state.order, "total", data.total);
    Vue.set(state.order, "date", data.date);

    //state.order = data;
    //state.order = { ...state.order, order: data };
  },


  setMenuPaths: (state, resp) =>{
    //console.log(resp);
    Vue.set(state, "selectel_path", resp.path);
    Vue.set(state, "menu_file", resp.menu);
  },

};

export default {
  state,
  getters,
  actions,
  mutations
};
