import Vue from "vue";
import user from "../modules/user"

//import menuJSON from '@/assets/global.json';
import API_connector from "@/utils/connector";

import menu_user_api from "@/assets/menu_user_api.json";

const state = {

    api: new API_connector(),

    left_menu: {},
    loaded: false,

    menu_user_api: menu_user_api,

    origHostName:   "none",
    devHostName:    "none",

    playhouses:{
        data: [],
        status: "none",
        loaded: false
    },

    playhouse: {

        loaded:     false,
        isGlobal:   false,
        id:         0,
        data:       {},

    }





};

const getters = {

    getLeftMenu:            state => state.left_menu,
    isMenuLoaded:           state => state.loaded,

    getPlayhouses:          state => state.playhouses.data,
    getPlayhouse:           state => state.playhouse,
    isPlayhousesLoaded:     state => state.playhouses.loaded,

    getHostname: (state) => {

        // подставляем домен для теста, если локально зашли
        if (
          (state.origHostName==="localhost") ||
          (state.origHostName==="localhost.localdomain") ||
          (state.origHostName==="127.0.0.1")
        ){
            return state.devHostName;
        }
        else{
            return state.origHostName;
        }
    }

};

const actions ={

    // определить в каком мы театре
    definePlayhouse:({state, commit}, data) =>{

        //console.log('define');
        //console.log(data);

        commit("setConfigLoadMessage", "Загружаем площадку");

        let domain = data.hostname.split(".");
        let allowPlayhouses = [];

        state.playhouses.data.forEach((playhouse) =>{
            data.allowDomain.forEach((DID)=>{
                if (DID===playhouse.id){
                    allowPlayhouses.push(playhouse);
                    return 0;
                }
            })
        })

        let t = allowPlayhouses.find(x=>x.host===domain[0]);

        // мы не в конкретном театре, грузим глобал
        if (t===undefined){
            commit('setGlobalPlayhouse');
            commit("setConfigLoadMessage", "Загрузили глобальную площадку");

        }

        // мы в каком-то конкретном - заносим в state
        else{
            commit('setLocalPlayhouse', t);
            commit("setConfigLoadMessage", "Загрузили площадку");
        }

        commit("setConfigLoadStatus", {module:"playhouse", value: true});

    },


    loadPlayhouses:({commit, state}, data) =>{

        commit("setConfigLoadMessage", "Загружаем площадки");
        commit('setPlayhousesStatus', 'begin');

        return state.api.getPlayhouses(data.full)
            .then(resp => {
                //console.log('playhouse ready');
                //console.log(resp);

                commit('setPlayhouses', resp);
                commit('setPlayhousesStatus', 'ready');
                commit("setConfigLoadMessage", "Загрузили площадки");
                commit("setConfigLoadStatus", {module:"playhouses", value: true});

            });
    },

    // афиша, обзор, билеты требуют вход
    loadMenuUserAPI:({commit})=>{

        //console.log('load user Api menu');
        //console.log(state.menu_user_api);

        commit("setConfigLoadMessage", "Загружаем меню");

        commit('setLeftMenu', state.menu_user_api);

        commit("setConfigLoadMessage", "Загрузили меню");
        commit("setConfigLoadStatus", {module:"left_menu", value: true});

    },

    loadMenuUserLocal:()=>{
        console.log('load user local menu');
        console.log('Пока нет - грузить и делать авторизацию локальную');
    },

    loadMenuUserGlobal:()=>{
        console.log('load user global menu');
        console.log('Пока нет - грузить и делать глобальное меню');
    },


    loadNavigationMenu:({commit}) =>{

        fetch("https://202702.selcdn.ru/zakaz/d/"+user.state.selectel_path+"/json/ui/"+user.state.menu_file, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then((data) => {

                //console.log(data);

                commit("setLeftMenu", data);

                //Vue.set(state, "nav_menu2", data);
            });

    },

}


const mutations = {

    setHostName:(state, hostname) =>{
        Vue.set(state, "origHostName", hostname);
    },

    setDevHostName:(state, hostname) =>{
        Vue.set(state, "devHostName", hostname);
    },

    setGlobalPlayhouse:(state) =>{

        Vue.set(state.playhouse, "isGlobal", true);
        Vue.set(state.playhouse, "id", 0);
        Vue.set(state.playhouse, "data", {});
        Vue.set(state.playhouse, "loaded", true);

    },

    setLocalPlayhouse:(state, data) =>{

        Vue.set(state.playhouse, "isGlobal", false);
        Vue.set(state.playhouse, "id", data.id);
        Vue.set(state.playhouse, "data", data);
        Vue.set(state.playhouse, "loaded", true);

    },

    setPlayhousesStatus:(state, status) =>{

        //state.playhouses.status = status;
        Vue.set(state.playhouses, "status", status);
    },

    setPlayhouses:(state, data)=>{

        Vue.set(state.playhouses, "data", data);
        Vue.set(state.playhouses, "loaded", true);

    },

    setLeftMenu:(state, data)=>{

        Vue.set(state, "loaded", false);
        Vue.set(state, "left_menu", data)
        Vue.set(state, "loaded", true);

    }

}

export default {
    state,
    getters,
    actions,
    mutations
};
