<template src="./IframeUser.html"></template>

<script>


import { mapGetters } from "vuex";
//import PaymentUser from "@/components/dialog/PaymentUser/PaymentUser";
//import UserBar from "/src/components/brick/UserBar"


export default {
  name: "IframeUser",
  //components: {PaymentUser, UserBar},




  data () {
    return {

      value: 2,


    }
  },

  created(){

    //console.log("iframe template");

  },

  computed: {

    ...mapGetters([

        "getLeftMenu",
        "isMenuLoaded",

        "isShowBreadcrumbs",
        "getBreadcrumbs",

        "getProfileInfo",

        "getPageInfo",
        "getUserCart",
    ]),

  },

  mounted() {



  },



  methods:{


  },
}
</script>

<style scoped>



</style>
