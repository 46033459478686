<template src="./UserBar.html"></template>

<script>


import {mapGetters} from "vuex";

export default {
  name: "UserBar",

  props:{

    title: String,
    event_id: { type: Number},
    content:  { type: undefined },
    sectors: {},
    color:    { type: String,    default: "blue"},
  },


  data: () => ({

    m_user_profile: false,
    selectedProfileItem: -1,



    loaded: true,

  }),

  created(){

    console.log(this.content);

    // если ли данные для отрисовки
    this.loaded = this.content !== undefined;

  },

  computed:{

    ...mapGetters([
      "getProfileInfo",
    ]),

  },



  methods:{

    logout: function() {

      this.selectedProfileItem = -1;
      this.$store.dispatch("logout");
      this.m_user_profile = false;

    }



  }

}
</script>

<style scoped>

</style>
