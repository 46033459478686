<template src="./StartUser.html"></template>

<script>


import { mapGetters } from "vuex";
import PaymentUser from "@/components/dialog/PaymentUser/PaymentUser";
import UserBar from "/src/components/brick/UserBar"


export default {
  name: "StartUser",
  components: {PaymentUser, UserBar},




  data () {
    return {

      main_drawer_small:false,
      second_drawer_small:false,
      second_drawer_none: false,

      second_drawer_name: "Заголовок",

      // menu: menuJSON,
      menu2: [],

      drawer: null,
      drawer2: null,

      value: 1,

    }
  },

  created(){

  },

  computed: {

    ...mapGetters([

        "getLeftMenu",
        "isMenuLoaded",

        "isShowBreadcrumbs",
        "getBreadcrumbs",




        "getProfileInfo",

        "getPageInfo",
        "getUserCart",
    ]),



    second_drawer_width2: function(){
      if (!this.second_drawer_small){
        return 0
      }
      else{
        return -188
      }
    },

    second_drawer_none_width: function(){
      if (this.second_drawer_none){
        if (this.second_drawer_small){
          return -56
        }
        else{
          return -244
        }
      }
      else{
        return 0
      }
    },

    second_drawer_width: function(){

      if (!this.main_drawer_small){
        return 500
      }
      else{
        return 300
      }
    },

    drawer_min_icon: function(){
      if (!this.main_drawer_small){
        return "mdi-chevron-left"
      }
      else{
        return "mdi-chevron-right"
      }
    },

    drawer_min_icon2: function(){
      if (!this.second_drawer_small){
        return "mdi-chevron-left"
      }
      else{
        return "mdi-chevron-right"
      }
    }



  },

  mounted() {

  },



  methods:{


    login_local(){

      if (this.isUserAuthenticated){
        console.log('user_token_exist');
      }
      else{
        console.log('no user_token');

        // в каком-то театре - домене
        if ( this.getPlayhouse.id>0){
          this.$store.dispatch("localAPILogin", {
            theater : this.getPlayhouse.id
          })
              .then(()=>{
                console.log('auth API user is ready');
              });
        }
        // глобально - переходим на глобальную афишу
        else{
          console.log('global no need API');

          const path = '/g'
          if (this.$route.path !== path) this.$router.push(path)

        }



      }


    },



    loginInit(){

      if (this.isGlobalAuthenticated){
        console.log('global_token_exist');
      }
      else{
        console.log('no global_token');

        this.$store.dispatch("globalLogin")
            .then(()=>{
              console.log('auth global is ready');
            });
      }

      this.$store.dispatch("loadPlayhouses", {full:true})
          .then(()=>{
            //console.log('pl ready');

            this.$store.dispatch(
                "definePlayhouse",
                {
                  hostname: window.location.hostname,
                  allowDomain: this.getConfig.allowPlayhouses.domain,
                })
                .then(()=>{
                  //console.log('playhouse ready');
                  this.login_local();

                })

          })



    },

    ttest(){

      this.$store.dispatch('doShowPaymentUser');

    },

    load_auth_menu: function(){

      fetch("http://data.zakaz.cloud/d/E39FFEA32C/json/ui/master.json", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
          .then(response => response.json())
          .then((data) => {

            console.log(data);
            //this.menu = data;
            // this.show = data.data;
            // this.show_ready = true;
          });

    },


    onclick_min_drawer: function(){
      this.main_drawer_small = !this.main_drawer_small;

    },

    onclick_min_drawer2: function(){
      this.second_drawer_small = !this.second_drawer_small;
    },

    onclick_sub(location, inner, name){

      this.second_drawer_name = name;

      //this.$router.push(location);

      console.log(location);

      if (!Array.isArray(inner)){
        this.second_drawer_none = true;
      }
      else{
        this.second_drawer_none = false;
      }

      this.menu2 = inner;
    },

    logout: function() {
      //this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push("/login"));
      this.$store.dispatch('loadNavigationMenuDefault');
    }

  },
}
</script>

<style scoped>


.open{
  padding-left:200px;
}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled).second_menu{
  background-color: #ffe19f;
  border-left:5px solid #ffb858;

}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled).second_menu_small{
  border-left:5px solid #ffb858;
}


.v-list-item--active.second_menu{
  border-left:5px solid #ffb858;
}

.v-list-item--active.second_menu_small{
  border-left:5px solid #ffb858;
}

</style>
