'use strict';

//import hex_fucn from '@/utils/sha512'

import {sha512} from "js-sha512";

export default class API_connector{

    static api_base_url             = "https://api.zakaz.cloud"
    static api_base_dev_url         = "http://dev.api.zakaz.cloud"
    //static api_url                  = API_connector.api_base_dev_url
    static api_url                  = API_connector.api_base_url

    static selectel_path_domain     = "http://data.zakaz.cloud/d/";
    static selectel_self_path        = "https://202702.selcdn.ru/zakaz/d/";
    static selectel_path            = API_connector.selectel_self_path

    static token                    = localStorage.getItem("user-token") || "";
    static token_global             = localStorage.getItem("global-token") || "";

    static public_inner_token       = "lkifdjffd09IUJDLF";
    //public_token;

    hello(){
        console.log('api hello');
    }

    initToken(){
        API_connector.token             = localStorage.getItem("user-token") || ""
        API_connector.token_global      = localStorage.getItem("global-token") || ""
    }




    async post(url, data, json=true, isPublicQuery = false){

        //console.log('post');

        //let tk = localStorage.getItem("user-token");

        //API_connector.token;

        let headers = {
            'Authorization': 'Bearer '+ API_connector.token,
            'Content-Type': 'application/json'
        }

        // общий запрос, не от какого-либо театра, без токена
        if (isPublicQuery){

            data.global.key = API_connector.public_inner_token;

            headers = {
                'Content-Type': 'application/json'
            }
        }

        //console.log(data);
        let jj = JSON.stringify(data);
        //console.log(jj);
        //console.log(headers);

        let r = await fetch(API_connector.api_url + url,
            {
                method: "POST",
                body: jj,
                headers: headers
            })

        //console.log(r);

        if (json){
            //console.log(r.json);
            return r.json();

        }
        else{
            return r.text();
        }



    }

    async get(url, json=true){

        let tk = localStorage.getItem("user-token");
        //console.log(tk);

        let r = await fetch(API_connector.api_url + url,
            {
                method: "GET",
                headers: {
                    //'Authorization': 'Bearer '+ API_connector.token,
                    'Authorization': 'Bearer '+ tk,
                    'Content-Type': 'application/json'
                }
            })

        if (json){
            return r.json();
        }
        else{
            return r.text();
        }

    }

    async getSelectel(url){

        let r = await fetch(API_connector.selectel_path + url,
            {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            })

        return r.json();

    }

    async loadPermittedLayout(path){

        //console.log(path);

        let t = await this.getSelectel(path+'/json/layout.json');
        return t
    }


    async loadPlayhouses(){

        const post_data = {

            global:{
                action:"find",
                module:"common"
            },
            data:{
                mode:{
                    target:"general",
                    part:"playhouse",
                    act:"list"
                }
            }
        };

        let t = await this.post('/common', post_data, true, true);
        return t.data;

    }


    async addSeasonPass(data){

        const post_data = {

            global:{
                format:"raw",
                action:"add",
                module:"season",
                version:2,
            },

            data: data

        }

        let t = await this.post('/season', post_data);
        return t.data
    }

    async addPeople(data){

        const post_data = {

            global:{
                format:"raw",
                action:"add",
                module:"people",
                version:2,
            },

            data: data

        }

        let t = await this.post('/people', post_data);
        return t.data
    }

    async addRole(data){

        const post_data = {

            global:{
                format:"raw",
                action:"add",
                module:"role",
                version:2,
            },

            data: data

        }

        let t = await this.post('/role', post_data);
        return t.data
    }

    async updatePeople(data){

        const post_data = {

            global:{
                format:"raw",
                action:"update",
                module:"people",
                version:2,
            },

            data: data

        }

        let t = await this.post('/people', post_data);
        return t.data
    }

    async updateRole(data){

        const post_data = {

            global:{
                format:"raw",
                action:"update",
                module:"role",
                version:2,
            },

            data: data

        }

        let t = await this.post('/role', post_data);
        return t.data
    }

    async loadLayoutsGroupByPlaces(layout_ids){

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"layout",
                version:2,
            },

            data:{
                filter:{
                    enabled: 1,
                    id: layout_ids
                },
                fields:{
                    id:1,
                    place_id:1,
                    name:1,
                    seat_total:1
                },
                param:{
                    sort:'id',
                    desc:true,
                    limit:150,
                    group:'place_id',
                    group_sort:'timedate'
                },
                detail:{
                    place_id:{
                        fields:{
                            id:1,
                            name:1,
                            ticket_txt1:1
                        }
                    }
                }
            }
        };

        let t = await this.post('/layout', post_data);
        return t.data
    }


    async addEvent(data){

        const post_data = {

            global:{
                format:"raw",
                action:"add",
                module:"event",
                version:2,
            },

            data: data
        };

        let t = await this.post('/event', post_data);
        return t.data
    }


    async auth(data){

        console.log('connector api');

        const post_data = {

            identifier:  data.username,
            //pass_hash:   hex_fucn.hex_sha512(data.password),
            pass_hash:   sha512(data.password),

            theater: data.theater_select,

            is_hash: true,
            is_admin: data.isAdmin,
        };

        return await this.post('/auth/local', post_data);

    }

    async addShow(data){

        const post_data = {

            global:{
                format:"raw",
                action:"add",
                module:"show",
                version:2,
            },

            data: data
        };

        let t = await this.post('/show', post_data);
        return t.data
    }


    async getReport(data, json=false){

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"report",
                version: 2,
            },

            data: data
        };

        let t = await this.post('/report', post_data, json);

        if (json){
            return t.data
        }
        else{
            return t;
        }



    }

    // поставить снять бронь
    async setArmour(data){

        const post_data = {

            global:{
                format:"raw",
                action:"armour",
                module:"seat_order",
                version: 2,
            },

            data: data
        };

        let t = await this.post('/seat_order', post_data);
        return t.data

    }

    async addMongerOrderGroup(data){

        const post_data = {

            global:{
                format:"raw",
                action:"add",
                module:"monger_order_group",
                version:2,
            },

            data: data
        };

        let t = await this.post('/monger_order_group', post_data);
        return t.data

    }


    async updateMongerOrderGroup(data){

        const post_data = {

            global:{
                format:"raw",
                action:"update",
                module:"monger_order_group",
                version:2,
            },

            data: data
        };

        let t = await this.post('/monger_order_group', post_data);
        return t.data

    }

    async updateLayoutSA(data){

        const post_data = {

            global:{
                format:"raw",
                action:"update_sa",
                module:"layout",
                version:2,
            },

            data: data
        };

        let t = await this.post('/layout', post_data);
        return t.data

    }


    async updateMongerOrder(data){

        const post_data = {

            global:{
                format:"raw",
                action:"update",
                module:"monger_order",
                version:2,
            },

            data: data
        };

        let t = await this.post('/monger_order', post_data);
        return t.data

    }



    async addMongerOrder(data){

        const post_data = {

            global:{
                format:"raw",
                action:"add",
                module:"monger_order",
                version:2,
            },

            data: data
        };

        let t = await this.post('/monger_order', post_data);
        return t.data

    }

    async addMongerOrderRefund(data){

        const post_data = {

            global:{
                format:"raw",
                action:"add",
                module:"monger_order_refund",
                version:2,
            },

            data: data
        };

        let t = await this.post('/monger_order_refund', post_data);
        return t.data

    }

    async addOrder(data){

        const post_data = {

            global:{
                format:"raw",
                action:"add",
                module:"order",
                version:2,
            },

            data: data
        };

        let t = await this.post('/order', post_data);
        return t.data
    }

    async addRefund(data){

        const post_data = {

            global:{
                format:"raw",
                action:"add",
                module:"refund",
                version:2,
            },

            data: data
        };

        let t = await this.post('/refund', post_data);
        return t.data
    }

    async findMObyLink(data){

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"monger_order",
                version:2,
            },

            data: data
        };

        let t = await this.post('/monger_order', post_data);
        return t.data
    }


    async findMObyID_seat_detail(mo_id, mo_type){

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"monger_order",
                version:2,
            },

            data: {
                mode:{
                    type: "seat_detail",
                    html: true
                },
                filter:{
                    type:   mo_type,
                    id:     mo_id
                },
                fields:{
                    id:1,
                    order_cancel:   1,
                    order_count:    1,
                    comment:        1,
                    status:         1,
                    order_create:   1,
                    event_id:       1,
                    mog_id:         1,
                    close_bux_invoice: 1
                },
                param:{
                    sort:"id",
                    desc: true,
                    limit:1
                },
                detail:{
                    event_id:{
                        fields:{
                            show_id:    1,
                            timedate:   1,
                            status:     1
                        },
                        detail:{
                            payment_id:{
                                fields:{
                                    name:   1,
                                    age :   1,
                                    time :  1
                                }
                            }
                        }
                    }
                }
            }
        };

        let t = await this.post('/monger_order', post_data);
        return t.data
    }

    async addArmour(data){

        const post_data = {

            global:{
                format:"raw",
                action:"add",
                module:"armour",
                version:2,
            },

            data: data
        };

        let t = await this.post('/armour', post_data);
        return t.data
    }

    // выслать ссылку на восстановление
    // проверить ссылку
    // поменять пароль
    async admUser(data){

        const post_data = {

            global:{
                format:"raw",
                action:"adm",
                module:"user",
                version:2,
            },
            data: data,
        }

        //console.log(post_data);
        let t = await this.post('/user', post_data, true, true);
        return t.data

    }

    async findSmartLink(data){

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"ticket",
                version:2,
            },

            data: data
        };

        let t = await this.post('/ticket', post_data);
        return t.data
    }

    async findMongerOrdersList(filter, param = null){

        if (param ==null){

            param = {
                sort:'id',
                desc:false,
                limit:150
            }

        }

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"monger_order",
                version:2,
            },

            data:{
                filter: filter,

                fields:{
                    monger_id:1,
                    id:1,
                    order_price:1,
                    order_count:1,
                    comment:1,
                    status:1,
                    order_create:1,
                    event_id:1,
                    mog_id:1,
                    type:1,
                    close_bux_invoice:1
                },
                param: param,

                detail:{
                    event_id:{
                        fields:{
                            show_id:1,
                            timedate:1,
                            status:1,
                        },
                        detail:{
                            show_id:{
                                fields:{
                                    name:1,
                                    age:1,
                                    time:1
                                }
                            }
                        }
                    }
                }

            }
        };

        let t = await this.post('/monger_order', post_data);
        return t.data


    }



    async findMongerOrdersRefund(filter, param = null){

        if (param ==null){

            param = {
                sort:'id',
                desc:true,
                limit:150
            }
        }

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"monger_order_refund",
                version:2,
            },

            data:{
                filter: filter,

                fields:{

                    id:                     1,
                    mo_id:                  1,
                    event_id:               1,
                    count:                  1,
                    price:                  1,
                    status:                 1,
                    comment:                1,
                    date:                   1,
                    close_ofd_operation_id: 1,
                    close_ofd_receipt_id:   1
                },

                param: param,

                detail:{
                    event_id:{
                        fields:{
                            show_id:    1,
                            timedate:   1,
                            status:     1
                        },
                        detail: {
                            show_id: {
                                fields: {
                                    id: 1,
                                    name: 1
                                }
                            }
                        }
                    },
                    mo_id: {
                        fields: {
                            id: 1,
                            monger_id: 1,
                            mog_id: 1,
                            comment: 1
                        },
                        detail: {
                            monger_id: {
                                fields: {
                                    id: 1,
                                    name: 1
                                }
                            }
                        }
                    }
                }

            }
        };

        let t = await this.post('/monger_order_refund', post_data);
        return t.data
    }



    async findCertificate(data){

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"certificate",
                version:2,
            },
            data: data
        }

        //console.log(post_data);

        let t = await this.post('/certificate', post_data);
        return t.data

    }


    async findCertificateGroup(filter, param = null){

        if (param ==null){

            param = {
                sort:'id',
                desc:false,
                limit:500
            }

        }

        const data = {

            mode:{
                type:"group"
            },

            filter: filter,

            fields:{
                id:1,
                name:1,
                desc:1,
                price:1,
                count:1,
                type:1,
                status:1,
                base_event:1,
                enabled:1,
                created:1
            },
            param: param,

        };

        return this.findCertificate(data)


    }

    async findCertificateCodes(filter, param = null){

        if (param ==null){

            param = {
                sort:'id',
                desc:false,
                limit:1000
            }

        }

        const data = {

            mode:{
                type:"codes"
            },

            filter: filter,

            fields:{
                id:         1,
                group_id:   1,
                code:       1,
                sold:       1,
                used:       1,
                blocked:    1,
                enable:     1,
                price:      1,
                date_add:   1,
                date_sale:  1,
                date_use:   1,
                date_check: 1,
                date_block: 1
            },
            param: param,

        };

        return this.findCertificate(data)


    }

    async getMongerOrdersGroupListOne(mog_id, print_form=false){

        let print = "";

        if (print_form){
            print = "/print";
        }

        let t = await this.get('/monger_order_group/'+mog_id+print, !print_form);

        //console.log(t);

        if (print_form){
            return t
        }
        else{
            return t.data
        }


    }


    async getMongerOrdersGroupList(filter){


        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"monger_order_group",
                version:2,
            },

            data:{
                filter: filter,

                fields:{
                    id:1,
                    monger_id:1,
                    price:1,
                    count:1,
                    comment:1,
                    status:1,
                    date:1,
                    type:1
                },
                param:{
                    sort:'id',
                    desc:false,
                    limit:1500
                },

                detail:{
                    monger_id:{
                        fields:{
                            id:1,
                            name:1
                        }
                    }
                }

            }
        };

        let t = await this.post('/monger_order_group', post_data);
        return t.data


    }


    async loadLayouts(layout_ids){

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"layout",
                version:2,
            },

            data:{
                filter:{
                    enabled: 1,
                    id: layout_ids
                },
                fields:{
                    id:1,
                    place_id:1,
                    name:1,
                    seat_total:1
                },
                param:{
                    sort:'id',
                    desc:true,
                    limit:150
                },

            }
        };

        let t = await this.post('/layout', post_data);
        return t.data
    }


    async getMongerlist(){

        let t = await this.get('/mongerlist/extra');
        return t.data
    }


    async getPeoplelist(){

        let t = await this.get('/people');
        return t.data
    }

    async getPeople(id){

        let t = await this.get('/people/'+id);
        return t.data
    }

    async findRole(ids){

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"role",
                version:2,
            },

            data:{
                filter:{
                    id: ids
                },
                fields:{
                    id:1,
                    name_show:1,
                    name_actor:1,
                    actor_id:1,
                    show_id:1,
                    get_in_only:1,
                    position:1,
                },
                param:{
                    sort:'id',
                    desc:true,
                    limit:150
                },

            }
        };

        let t = await this.post('/role', post_data);
        return t.data
    }

    async findRoleByActor(actor_id){

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"role",
                version:2,
            },

            data:{
                filter:{
                    actor_id: actor_id
                },
                fields:{
                    id:1,
                    name_show:1,
                    name_actor:1,
                    actor_id:1,
                    show_id:1,
                    get_in_only:1,
                    position:1,
                },
                param:{
                    sort:'id',
                    desc:true,
                    limit:150
                },
                detail:{
                    show_id:{
                        fields:{
                            id:1,
                            name:1
                        }
                    }
                }

            }
        };

        let t = await this.post('/role', post_data);
        return t.data
    }



    async findRoleByShow(show_id){

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"role",
                version:2,
            },

            data:{
                filter:{
                    show_id: show_id
                },
                fields:{
                    id:1,
                    name_show:1,
                    name_actor:1,
                    actor_id:1,
                    show_id:1,
                    get_in_only:1,
                    position:1,
                },
                param:{
                    sort:'id',
                    desc:true,
                    limit:150
                },
                detail:{
                    actor_id:{
                        fields:{
                            id:1,
                            name:1,
                            family:1

                        }
                    }
                }

            }
        };

        let t = await this.post('/role', post_data);
        return t.data
    }


    async findTicketBySeat(event_id, seat_id){

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"ticket",
                version:2,
            },

            data:{
                mode:{
                    target:"seat",
                    output_1:"pdf_file",
                    output:"pdf",
                    output_:"json"
                },

                event:  event_id,
                seat:   seat_id

            }
        };

        let t = await this.post('/ticket', post_data, true);
        return t.data
    }

    async findTicketsByMailPart(mail){

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"ticket",
                version:2,
            },

            data:{
                mode:{
                    target:"mail_part",
                    output:"json"
                },

                mail:  mail

            }
        };

        let t = await this.post('/ticket', post_data, true);
        return t.data
    }


    async findTicketsByPayment(payment_id){

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"ticket",
                version:2,
            },

            data:{
                mode:{
                    target:"payment",
                    output:"pdf_file",

                    output_1:"pdf",
                    output_:"json"
                },

                payment_id:  payment_id

            }
        };

        let t = await this.post('/ticket', post_data, true);
        return t.data
    }


    async findSeatSectors(){

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"global",
                version:2,
            },

            data:{
                mode:{
                    target:"sector",
                    part:"",
                    act:"find",
                },

                filter:{
                },
                fields:{
                    id:             1,
                    name:           1,
                    ticket:         1,
                    ticket_short:   1
                },
                param:{
                    sort: "id",
                    desc: false,
                    limit: 150
                },
                detail:{}
            }
        };

        let t = await this.post('/global', post_data, true, true);
        return t.data
    }


    async findTicketTypesByIds(IDs){

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"global",
                version:2,
            },

            data:{
                mode:{
                    target:"ticket_types",
                    part:"",
                    act:"find",
                },

                filter:{
                    id:IDs
                },
                fields:{
                    id:             1,
                    name:           1,
                    description:    1
                },
                param:{
                    sort: "id",
                    desc: true,
                    limit: 150
                },
                detail:{}
            }
        };

        let t = await this.post('/global', post_data, true);
        return t.data
    }


    async loadLayout_SP(layout){

        let t = await this.get('/layout/'+layout+'/sp');
        return t.data
    }

    async loadLayout_PM(layout, price_map){

        let t = await this.get('/layout/'+layout+'/spz/'+ price_map);
        return t.data
    }

    async getMongerOrderRefund(mor_id){

        let t = await this.get('/monger_order_refund/'+mor_id);
        return t.data
    }

    async getMongerOrder(mo_id=0){

        let s = "";

        if (mo_id>0){
            s = "/"+mo_id;
        }

        let t = await this.get('/monger_order'+ s);
        return t.data
    }

    async getMongerOrderExtra(mo_id){

        let t = await this.get('/monger_order/'+mo_id+'/extra');
        return t.data
    }

    async loadLayout_PM_list(layout){

        let t = await this.get('/layout/'+layout+'/sz');
        return t.data
    }

    async loadLayout_AM(layout, armour_map){

        let t = await this.get('/layout/'+layout+'/spa/'+ armour_map);
        return t.data
    }



    async loadLayout_AM_list(layout){

        let t = await this.get('/layout/'+layout+'/sa');
        return t.data
    }

    async loadShowList(){

        let t = await this.get('/showlist');
        return t.data
    }

    async loadRoleList(){

        let t = await this.get('/role');
        return t.data
    }

    async loadSeasonList(){

        let t = await this.get('/season');
        return t.data

    }


    async findPayments(post_data){

        let t = await this.post('/payment', post_data);
        return t.data
    }


    async findShows(post_data){

        let t = await this.post('/show', post_data);
        return t.data
    }

    async findShowByIDs(IDs){

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"show"
            },

            data:{
                filter:{
                    id: IDs
                },
                fields:{
                    id:1,
                    culture_id:1,
                    name:1,
                    author:1,
                    genre:1,
                    description:1,
                    picture:1,
                    video:1,
                    pictures_count:1,
                    time:1,
                    age:1,
                    link:1,
                    tag_status_id:1
                },
                param:{
                    sort:'id',
                    desc:true,
                    limit:500
                },
                detail:{}
            }
        };

        let t  = this.findShows(post_data);
        return t;

    }



    async findEvents(post_data){

        let t = await this.post('/event', post_data);
        return t.data
    }

    async findScan(data){

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"scan"
            },

            data:data
        };

        let t = await this.post('/scan', post_data);
        return t.data
    }






    async findEventsByStatus(status){

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"event"
            },

            data:{
                filter:{
                    status: status
                },
                fields:{
                    id:1,
                    show_id:1,
                    timedate:1,
                    status:1,
                    prices:1,
                    pushka:1,
                },
                param:{
                    sort:'id',
                    desc:true,
                    limit:2500,
                    group:'show_id',
                    group_sort:'timedate'
                },
                detail:{
                    show_id:{
                        fields:{
                            id:1,
                            name:1,
                            age:1,
                            time:1
                        }
                    }
                }
            }
        };

        let t  = this.findEvents(post_data);
        return t;

        //let tt = await this.post('/event', post_data);
        //return tt.data
    }



    async findEventByID(event_id, fields, detail){

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"event"
            },

            data:{
                filter:{
                    id: event_id
                },
                fields: fields,

                param:{
                    sort:'id',
                    desc:true,
                    limit:1
                },
                detail: detail
            }
        };

        let t  = this.findEvents(post_data);
        return t;

        //let tt = await this.post('/event', post_data);
        //return tt.data
    }


    async findEventsByShowStatus(show_id, status, onlyComing=false){

        let mode = {};

        if (onlyComing){
            mode = {
                target:     "coming",
                date_field: "timedate"
            }
        }

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"event"
            },

            data:{

                mode: mode,

                filter:{
                    status:     status,
                    show_id:    show_id
                },
                fields:{
                    id:1,
                    show_id:1,
                    timedate:1,
                    status:1,
                    prices:1,
                    price_z1:1,
                    price_z2:1,
                    price_z3:1,
                    price_z4:1,
                    price_z5:1,
                    price_z6:1,
                    pushka:1,
                    free_seat:1
                },
                param:{
                    sort:'id',
                    desc:true,
                    limit:2500,
                    group:'show_id',
                    group_sort:'timedate'
                },
                detail:{
                    show_id:{
                        fields:{
                            id:1,
                            name:1,
                            age:1,
                            time:1,
                            picture:1,
                            tag_status_id:1,
                            culture_id:1,
                            pictures_count:1,
                            description:1
                        }
                    }
                }
            }
        };

        let t  = this.findEvents(post_data);
        return t;

        //let tt = await this.post('/event', post_data);
        //return tt.data
    }



    async findEventsByShowStatusPushka(show_id, status, isPushka){

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"event"
            },

            data:{
                filter:{
                    status:     status,
                    pushka:     isPushka,
                    show_id:    show_id
                },
                fields:{
                    id:1,
                    show_id:1,
                    timedate:1,
                    status:1,
                    prices:1,
                    pushka:1,
                },
                param:{
                    sort:'id',
                    desc:true,
                    limit:2500,
                    group:'show_id',
                    group_sort:'timedate'
                },
                detail:{
                    show_id:{
                        fields:{
                            id:1,
                            name:1,
                            age:1,
                            time:1,
                            picture:1,
                            tag_status_id:1,
                            culture_id:1
                        }
                    }
                }
            }
        };

        let t  = this.findEvents(post_data);
        return t;

        //let tt = await this.post('/event', post_data);
        //return tt.data
    }


    async findEventsByStatusNoGroup(status){

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"event"
            },

            data:{
                filter:{
                    status: status
                },
                fields:{
                    id:1,
                    show_id:1,
                    timedate:1,
                    status:1,
                    prices:1,
                    pushka:1,
                },
                param:{
                    sort:'timedate',
                    desc:false,
                    limit:500
                },
                detail:{
                    show_id:{
                        fields:{
                            id:1,
                            name:1,
                            age:1,
                            time:1,
                            culture_id:1,

                        }
                    }
                }
            }
        };

        let t  = this.findEvents(post_data);
        return t;

        //let tt = await this.post('/event', post_data);
        //return tt.data
    }


    async findEventsZero(filter){

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"event"
            },

            data:{

                mode:{
                  target:"zero"
                },

                filter: filter,

                fields:{
                    id:1,
                    show_id:1,
                    name:1,
                    timedate:1,
                    status:1,
                    count:1,
                    comment:1,
                },
                param:{
                    sort:'timedate',
                    desc:false,
                    limit:500
                },
                detail:{
                    show_id:{
                        fields:{
                            id:1,
                            name:1,
                            age:1,
                            time:1,
                            culture_id:1,
                        }
                    }
                }
            }
        };

        let t  = this.findEvents(post_data);
        return t;
    }


    async getPushkaByEvent(event_id){

        //console.log(event_id);

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"culture"
            },

            data:{
                mode:{
                    target: "event"
                },
                filter:{
                    event_id:event_id,
                },
                fields:{
                    id:1,
                    qr:1,
                    status_id:1,
                    rid:1,
                    event_id:1,
                    seat_id:1,
                    fio:1,
                    phone:1,
                    status:1
                },
                param:{
                    sort:"id",
                    desc:false,
                    limit:1500
                }
            }
        };

        let t = await this.post('/culture', post_data);
        return t.data



    }


    async addPushkaToRegistryByPayment(payment_id, logged=false){

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"system"
            },

            data:{
                mode:{
                    target: "pushka",
                    part: "ticket",
                    act: "addByPaymentId",
                    UAT: "false",
                    log: logged
                },
                data:{
                    payment_id:payment_id
                }
            }
        };

        let t = await this.post('/system', post_data);
        return t.data

    }



    async getPushkaTicketByRID(rid){

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"culture"
            },

            data:{
                mode:{
                    target: "ticket",
                    type:"getByRID"

                },
                rid: rid
            }
        };

        let t = await this.post('/culture', post_data);
        return t.data

    }

    async visitPushkaTicketByRID(rid){

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"culture"
            },

            data:{
                mode:{
                    target: "ticket",
                    type:"visitByRID"

                },
                rid: rid
            }
        };

        let t = await this.post('/culture', post_data);
        return t.data

    }

    async refundPushkaTicketByRID(rid){

        //console.log(rid);

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"culture"
            },

            data:{
                mode:{
                    target: "ticket",
                    type:"refundByRID"

                },
                rid: rid
            }
        };

        console.log(post_data);

        let t = await this.post('/culture', post_data);
        return t.data

    }


    async findEventsByStatusFlatList(status){

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"event"
            },

            data:{
                filter:{
                    status: status
                },
                fields:{
                    id:1,
                    show_id:1,
                    timedate:1,
                    status:1,
                    prices:1
                },
                param:{
                    sort:'id',
                    desc:true,
                    limit:150,

                    group_sort:'timedate'
                },
                detail:{
                    show_id:{
                        fields:{
                            id:1,
                            name:1,
                            age:1,
                            time:1
                        }
                    }
                }
            }
        };

        let t  = this.findEvents(post_data);
        return t;

        //let tt = await this.post('/event', post_data);
        //return tt.data
    }




    async findRegistryByPeriod(date1, date2){

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"payment"
            },

            data:{

                mode:{
                    target:       "period",
                    date_field:   "create",
                    type:         "pushka"

                },

                filter: {
                    owner_user_id_: 3,
                    create: [date1, date2],
                    status_id: [56],
                    type: 30

                },
                fields:{
                    id:           1,
                    create:       1,
                    done:         1,
                    cancel:       1,
                    status:       1,
                    price:        1,
                    ticket_count: 1,
                    status_id:    1,
                    type:         1,
                    phone:        1,
                    fio:          1,
                    rrn:          1
                },
                param:{
                    sort:         "id",
                    desc:         false,
                    limit:        5000
                }
            }
        };

        let t  = this.findPayments(post_data);
        return t;

    }



    async findRefund(data){

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"refund"
            },
            data:data
        };

        let t = await this.post('/refund', post_data);
        return t.data
    }


    async getPlayhouses(full = false){

        //console.log('1');

        const post_data = {

            global:{
                action:"find",
                module:"common"
            },
            data:{
                mode:{
                    target:"general",
                    part:"playhouse",
                    act:"list",
                    full: full
                }
            }
        };

        //console.log(post_data);

        let t = await this.post('/common', post_data, true, true);
        return t.data

    }




    async getRefundList(){

        const data = {


            mode:{},
            filter: {},

            fields:{
                id:             1,
                payment_id:     1,
                date:           1,
                status:         1,
                status2:        1,
                count:          1,
                price:          1,
                user_id:        1,
                monger_id:      1,
                ofd_id:         1,
                bank_id:        1
            },
            param:{
                sort:         "id",
                desc:         true,
                limit:        100
            },

            detail:{
                payment_id:{
                    fields:{
                        rrn:1
                    }
                }
            }
        };

        let t  = this.findRefund(data);
        return t;

    }




    async updatePayment(base){

        const post_data = {

            global:{
                format:"raw",
                action:"update",
                module:"payment",
                version: 2,
            },

            data:{
                base:base
            }
        };

        let t = await this.post('/payment', post_data);
        return t.data
    }

    async updateEvent(data){

        const post_data = {

            global:{
                format:"raw",
                action:"update",
                module:"event",
                version: 2,
            },

            data:data
        };

        let t = await this.post('/event', post_data);
        return t.data

    }

    async updateShow(data){

        const post_data = {

            global:{
                format:"raw",
                action:"update",
                module:"show",
                version: 2,
            },

            data:data
        };

        let t = await this.post('/show', post_data);
        return t.data

    }

    // не конкретно cancel, просто именно для этого он чаще вызывается
    async updatePaymentCancel(id, status, form="order"){

        //console.log(form);

        const post_data = {

            global:{
                format:"raw",
                action:"update",
                module:"payment",
                version: 2,
            },

            data:{
                base:{
                    id: id,
                    status: status,
                    form: form
                }
            }
        };

        let t = await this.post('/payment', post_data);
        return t.data
    }


    async loadAfishaCulture(){

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"system",
                version: 2,
            },

            data:{
                mode:{
                    target: "pushka",
                    part: "afisha"
                }
            }
        };

        let t = await this.post('/system', post_data);
        return t.data

    }



    async loadAfisha(){

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"afisha",
                version: 2,
            },

            data:{
                mode:{
                    type: "coming",
                    limit: 150
                }
            }
        };

        let t = await this.post('/afisha', post_data);
        return t.data
    }


    async findAfishaGlobal(){

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"afisha",
                version: 2,

                key:      "lkifdjffd09IUJDLF", // global key
            },

            data:{
                mode:{
                    type: "global",
                    playhouses: [1,2],
                    limit: 150
                }
            }
        };

        let t = await this.post('/afisha', post_data);
        return t.data
    }

    // мероприятия с про.культуры

    async loadPushkaPro(culture_show_id= 0, only_confirmed= true){

        const post_data = {

            global:{
                format:"raw",
                action:"find",
                module:"afisha",
                version: 2,
            },

            data:{
                mode:{
                    type:               "pushka",
                    show_id:            culture_show_id,
                    only_confirmed:     only_confirmed,
                    limit: 150
                }
            }
        };

        let t = await this.post('/afisha', post_data);
        return t.data
    }


    async checkCoupon(data){
        const post_data = {
            global:{
                format:"raw",
                action:"find",
                module:"coupon",
                version: 2,
            },
            data: data
        };

        let t = await this.post('/coupon', post_data);
        return t.data
    }

    async checkUniPromoCode(event_id= 0, code= "", discount_ids = []){

        const post_data = {
            mode:{
                type: "UNI_PROMO",
            },
            filter: {
              event: event_id,
              code: code.toString(),
              discount_ids: discount_ids
            }
        };

        return this.checkCoupon(post_data);
    }


    async getEventAdm(id){

        let t = await this.get('/event/'+id+'/adm');
        return t.data
    }


    async getUserMe(){

        let t = await this.get('/user/me');
        return t.data;
    }


    async getEventKassa(id){

        let t = await this.get('/event/'+id+'/kassa');
        return t.data
    }

    async getPayment(id){

        let t = await this.get('/payment/'+id);
        return t.data
    }

    async getEventUser(id){

        let t = await this.get('/event/'+id);
        return t.data
    }

    async getEventInet(id, mo_id){

        let t = null;

        if (mo_id>0){
            t = await this.get('/event/'+id+'/inet/'+mo_id);
        }
        else{
            t = await this.get('/event/'+id+'/inet');
        }


        return t.data
    }



}

