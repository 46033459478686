<template src="./App.html"></template>

<script>

import { mapGetters } from "vuex";

import StartUser from "@/views/Start/StartUser";
import LoaderUser from "@/views/Start/LoaderUser";
import IframeUser from "@/views/Start/IframeUser";



export default {
  name: 'App',
  components: {  LoaderUser, StartUser, IframeUser},

  data: () => ({
    loading: false,

    isIframe: false,

  }),


  created() {

    console.clear();
    //console.log(window.location.hostname)
    this.isIframe = this.isIframePath(window.location.pathname);

    // this.$store.commit('setDevHostName', "puppet.3book.ru");
    this.$store.commit('setDevHostName', "samart.3book.ru");
    // this.$store.commit('setHostName', "sp.3book.ru");
    this.$store.commit('setHostName', 'samart.3book.ru');
    // this.$store.commit('setHostName', window.location.hostname);

    this.$store.dispatch('initConfig');
    //console.log(this.isIframe);

    if (this.isIframe){
      console.log('iframe');
    }
    else{
      console.log('no-iframe');
    }



  },

  mounted() {

    //console.log(this.$route);


  },

  computed: {

    ...mapGetters([
      "isConfigLoaded",
      "getColorFrame"
    ]),

    getBackgroundStyle(){
      let style
      if(!this.isIframe) style = "background: repeat url('/img/ifon_200t.jpg');"
      if(this.isIframe){
        if(!this.getColorFrame.bgFullFrame){
          style = "background: url("+this.getColorFrame.bgImage+") repeat;"
        }else{
          style = "background: url("+this.getColorFrame.bgImage+") center center no-repeat;background-attachment: fixed;background-size: 100%;"
        }
      }
      return style;
    }
  },



  methods:{

    isIframePath(pathname){

      let r = pathname.split('/');
      //console.log(r);

      return (r[1]==='i');
    }


  },
};
</script>

<style scoped>

.open{
  padding-left:200px;
}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled).second_menu{
  background-color: #ffe19f;
  border-left:5px solid #ffb858;

}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled).second_menu_small{
  border-left:5px solid #ffb858;
}


.v-list-item--active.second_menu{
  border-left:5px solid #ffb858;
}

.v-list-item--active.second_menu_small{
  border-left:5px solid #ffb858;
}

</style>
