import Vue from "vue";
//import API_connector from "@/utils/connector";

import breadCrumbsIframeJSON    from "../static/breadCrumbsIframe.json"
import breadCrumbsJSON          from "../static/breadCrumbs.json"

const state = {

    //api: new API_connector(),

    dPaymentUser: false,

    cPageInfo:{
        id: 16,
        name: "Название этой страницы"
    },


    cBreadcrumbsShow:       true,

    cBreadcrumbs:           breadCrumbsJSON.cBreadcrumbs,
    cBreadcrumbsIframe:     breadCrumbsIframeJSON.cBreadcrumbsIframe,

};

const getters = {

    getPaymentUser:             state => state.dPaymentUser,
    getPageInfo:                state => state.cPageInfo,
    getBreadcrumbs:             state => state.cBreadcrumbs,
    getBreadcrumbsIframe:       state => state.cBreadcrumbsIframe,
    isShowBreadcrumbs:          state => state.cBreadcrumbsShow,



}

const actions = {

    // eslint-disable-next-line no-unused-vars
    doShowPaymentUser:({commit, dispatch}) =>{

        commit("doShowPaymentUser_m");
    },

    // eslint-disable-next-line no-unused-vars
    doHidePaymentUser:({commit, dispatch}) =>{

        commit("doHidePaymentUser_m");
    },

    // eslint-disable-next-line no-unused-vars
    // loadAfisha:({commit, dispatch}) =>{
    //
    //     console.log('loadAfisha');
    //     state.api.hello();
    //     let pr2 = state.api.loadAfisha();
    //
    //     pr2.then( data =>{
    //         commit("loadAfisha", data);
    //
    //         dispatch('loadAfishaCulture')
    //
    //     })
    // },



}


const mutations = {

    setBreadcrumbIframeDisable:(state, data) =>{
        //console.log(data);
        Vue.set(state.cBreadcrumbsIframe[data.id], "disabled" , data.value);

    },

    // eslint-disable-next-line no-unused-vars
    doShowPaymentUser_m:(state, data) =>{

        Vue.set(state, "dPaymentUser", true);

    },

    // eslint-disable-next-line no-unused-vars
    doHidePaymentUser_m:(state, data) =>{

        Vue.set(state, "dPaymentUser", false);

    },

    setBreadcrumbs:(state, data) =>{

        console.log(state, data);

    }


}


export default {
    state,
    getters,
    actions,
    mutations
};
