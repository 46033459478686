import Vue from "vue";
import API_connector from "@/utils/connector";
import colorsJson from '@/assets/color.json';
import iframeJson from '@/assets/json/playhousesTheme/themes.json'

const state = {

    api: new API_connector(),
    colorsJson: colorsJson,
    iframeJson: iframeJson,
    themeSelect:0,
    themeName:"",
    iframeTheme:{
        "theme": "default",
        "loaderHall":"/img/loader/default-loader.gif",
        "bg":"",
        "bgImage":"",
        "bgFullFrame":false,
        "text":"",
        "btn":"",
        "btnAlt":"",
        "bgAlt":"",
        "bgOrder":"",

        "progressLine":"",
        "snakeTheme":"",
        "loaderTitle":"",
        "loaderText":"",
        "loaderBg":"",
        "loaderImg":"",
        "loaderLine":""
    },

    loaded:false,
    mongers:[],

};

const getters = {

    getColorZones:  state => state.colorsJson.zones,
    getColorMain:   state => state.colorsJson.main,
    getColorFrame:  state => state.iframeTheme,
    getSeatColorPixiArr: state =>{

        let colors = [];
        state.colorsJson.zones.forEach((x)=>{
            //console.log(x);
            colors.push(x['4']);
        })

        return colors;

    }

}

const actions = {
    // loadPlayhouseTheme:({commit},id)=>{
    loadPlayhouseTheme:({commit})=>{
        console.warn("Туть")
        commit("setConfigLoadMessage", "Загружаем цветовую тему");
        // fetch('/playhousesTheme/'+id+'.json')
        fetch('/playhousesTheme/themes.json')//@TODO - тут заменить путь на серверное хранение файла
        .then(response => response.json())
        // .then(response => response)//TODO проверить, если будет вынос файлов тем на сервер
        .then( (data) =>{
            console.warn(data)
            let theme = data ? data : iframeJson //подстраховочный с дефолтными темами

            commit("changeDefaultThemeJson",theme)

            commit('changeFrameTheme')

            commit("setConfigLoadStatus", {module: "colorTheme", value: true}); //TODO Включить обратно после кастомизации цвета
            commit("setConfigLoadMessage", "Загрузили цветовую тему");

            return;
        })
    }
}


const mutations = {
    changeDefaultThemeJson:(state,theme)=>{
        if(theme) Vue.set(state,'iframeJson',theme);
    },
    changeFrameTheme:(state,theme)=>{
        let url = new URL(window.location.href)
        let param = url.searchParams.get('theme')
        Vue.set(state,'themeName',param)
        let themeIndex
        if(state.themeName) {
            theme = state.themeName;
            themeIndex = iframeJson.findIndex(el=>el.theme === theme)
            if(themeIndex === -1) theme = ""
        }

        if(!theme){
            theme = 'default'
            themeIndex = iframeJson.findIndex(el=>el.theme === theme)
        }

        console.log(themeIndex)
        console.warn("HERE TOO")
        console.warn(theme) //TODO продолжить тут

        // this.$store.dispatch('changeFrameTheme');
        Vue.set(state,"themeSelect",themeIndex)
        Vue.set(state,"iframeTheme",state.iframeJson[state.themeSelect])
    }
}


export default {
    state,
    getters,
    actions,
    mutations
};
