<template src="./LoaderUser.html"></template>

<script>


import { mapGetters } from "vuex";


export default {
  name: "LoaderUser",
  components: {},


  data () {
    return {

      id:0,

    }
  },

  created(){

  },

  computed: {

    ...mapGetters([

      "getConfigLoadingMessage",
      "getColorFrame"

    ])

  },

  mounted() {

  },



  methods:{

  },
}
</script>

<style scoped>


</style>
