import Vue from "vue";
import API_connector from "@/utils/connector";

const state = {

    api: new API_connector(),

    load:{

        authGlobal:         false,

        authLocal:          false,

        authLocalAPI:       false,
        authLocalUser:      false,

        playhouses:         false,
        playhouse:          false,

        sectors:            false,

        left_menu:          false,

        colorTheme:         false,

        message:            "Начинаем загрузку"

    },

    settings: {

        loaded:true,

        allowPlayhouses:{

            // театры для глобальной афиши
            afisha:[
                1,       // площадь
                2,       // самарт
                4,       // куклы
                6,       // ДК
                11,      // камерная
                13,      // время тайн
            ],

            // можно входить
            login:[],

            // активные поддомены
            // сами поддомены берутся из navigation.getPlayhouses
            domain:[
                1, 2, 4, 6, 11, 13
            ],

            // можно показывать во фрейме
            // связать это с "родными" доменами учреждений
            // и с cors
            iframe:[]
        },



    },

    const:{

        seat_sectors:{
            data: [],
            status: "none",
            loaded: false
        },

        ticket_types:[],

        colors:[],
    }

};

const getters = {

    getConfigLoadingMessage: state => state.load.message,

    getConfig: state => state.settings,

    getSeatSectors: state => state.const.seat_sectors,

    isConfigLoaded: state => (

        state.load.playhouses &&
        state.load.playhouse &&

        state.load.sectors &&

        state.load.authGlobal &&
        state.load.authLocal &&     // когда просто глобально - нужно делать хак - так не сработает

        state.load.left_menu &&
        state.load.colorTheme
    ),

    getSectorName(sector_id){
        //console.log(sector_id);

        let r = state.const.seat_sectors.data.find(s=>s.id===sector_id).ticket;

        if (r===undefined){
          r = "Сектор Н";
        }

        return r;
    }

}

const actions = {

    loadSectors:({commit, state}) =>{

        commit("setConfigLoadMessage", "Загружаем сектора");
        commit('setSectorsStatus', 'begin');



        return state.api.findSeatSectors()
            .then(resp => {
                //console.log(resp);
                commit('setSectors', resp);
                commit('setSectorsStatus', 'ready');
                commit("setConfigLoadMessage", "Загрузили сектора");
                commit("setConfigLoadStatus", {module:"sectors", value: true});

            });
    },


    // самое начало загрузки
    // eslint-disable-next-line no-unused-vars
    initConfig({commit, dispatch, getters}){

        //console.log('initConfig action');

        commit("setConfigLoadMessage", "Начали загрузку");
        dispatch('loadPlayhouseTheme')
        .then(()=>{
            dispatch("loadPlayhouses", {full:true})
                .then(()=>{
                //console.log('playhouse after ready');

                dispatch(
                    "definePlayhouse",
                    {
                        hostname:       getters.getHostname,
                        allowDomain:    getters.getConfig.allowPlayhouses.domain,
                    })
                    .then(()=>{

                        dispatch("loadSectors")
                            .then(()=>{
                                dispatch('loginInit')
                            })

                        //console.log('after define');

                    })

            })
        })



        // commit("setConfigLoadMessage", "Загружаем файл настроек");
        //
        // // проверка на авторизаци2ю
        // if (user.getters.isAuthenticated(user.state)) {
        //
        //     dispatch("getUserMe").then(()=> {
        //
        //         commit("setConfigLoadMessage", "Загрузили профиль пользователя");
        //
        //         dispatch("loadLayoutConfig");
        //
        //         // TODO Основой конфиг загрузил - теперь делать вот эти функции
        //         dispatch('loadMongerlist');
        //         dispatch('loadEventlist');
        //     });
        // }
        //
        // else{
        //     // не авторизован
        //     commit('setDefaultNAMenu');
        //     commit('setConfigLoadStatus', {module:"menu", value: true})
        //     commit("setConfigLoadMessage", "Загрузили меню без авторизации");
        // }




    },





}


const mutations = {

    setSectors:(state, data)=>{

        Vue.set(state.const.seat_sectors, "data", data);

    },


    setSectorsStatus:(state, status) =>{

        //state.playhouses.status = status;
        Vue.set(state.const.seat_sectors, "status", status);
    },

    setConfigLoadStatus:(state, data) =>{

        // поставить статус, что мы какую-то часть конфига подгрузили и оно готово
        Vue.set(state.load, data.module, data.value);

    },

    setConfigLoadMessage:(state, message) =>{
        console.log(message)
        // поставить статус, что мы какую-то часть конфига подгрузили и оно готово
        Vue.set(state.load, "message", message);

    },



}


export default {
    state,
    getters,
    actions,
    mutations
};
