import Vue from "vue";

import config from "../modules/config"
import API_connector from "@/utils/connector";

const state = {

  api: new API_connector(),

  cart_info:{

    count: 0,
    price: 0,

    discount: 0,
    total_price: 0,

    event:{

      id:0,

      // надо ли целиком?
      data:   {},

      loaded: false,
    },

    seats:{

      ids:  [],

      // {id, price, zone(id)}
      data: [],

      info: [],

      ready: false

    }

  },

  user_info:{

    payment_type:0,

    email: "",
    phone: "",

    family: "",
    name:   "",
    fname:  "",

    fio:    "",

    isGood: false,

  },


  order_result:{

    order_id:0,
    bank_link: "",
    smartlink: "",

    ready:false,
    good: false,
    loading: false,

  },

  discount_info: {
    active_discount_id: 0,
    discounts: [],
  }

};

const getters = {

  getUserCart:          state=> state.cart_info,
  getUserCartInfo:      state=> state.user_info,
  getOrderIframeResult: state=> state.order_result,
  getDiscounts:         state=> state.discount_info,

  // если ли в заказе такой билет
  getCartTicketByID: (state) => (seat_id) =>{
    let t,r;
    state.cart_info.seats.info.forEach((sector)=>{
      t = sector.data.find((s)=>{return s.id===seat_id});
      if (t===undefined){
        console.log('no');
      }
      else{
        r = {
          id: seat_id,
          sector: sector.info,
          data : t
        }
      }

    })

    return r;
  },

};

const actions = {




  addIframeOrder:({state, commit})=>{

    let result = {

      order_id:   0,
      bank_link:  "",
      smartlink:  "",

      good:   false,
      ready:  false,
      loading: true,
    }

    commit(
        "setOrderResult",
        JSON.parse(JSON.stringify(result))
    );



    let  payment_type = "";
    if (state.user_info.payment_type===30){
      payment_type = "pushka";
    }

    // base.total = order1.price + order2 ... а не как сейчас оба равны total
    // но в данном случае - у меня не корзина, а именно один заказ и одно мероприятие
    let post_data = {

      base:{
        email:      state.user_info.email,
        total:      state.cart_info.total_price,
        user:       0,
        mode:       "inet2",
        type:       payment_type,
        isNameless: true,

        fio:        state.user_info.fio,
        phone:      state.user_info.phone,

        link:       false,

      },
      orders:[
        {
          event:    state.cart_info.event.id,
          culture:  state.cart_info.event.data.show.culture_id,

          discounted:       state.discount_info.active_discount_id>0,
          discounted_price: state.cart_info.discount,
          discount_id:      state.discount_info.active_discount_id,

          price:    state.cart_info.price,
          seats:    state.cart_info.seats.ids
        }
      ]
    }

    console.log(post_data);

    state.api.addOrder(post_data)
      .then( data =>{

        result.ready      =   true;
        result.loading    =   false;

        if (data.data.b){

          result.order_id   =  data.data.payment;
          result.bank_link  =  data.data.bank;
          result.smartlink  =  data.data.page;

          result.good       =   true;
          result.ready      =   true;
        }

        commit(
            "setOrderResult",
            JSON.parse(JSON.stringify(result))
        );

      })
  },

  //addIframeOrder:({state, commit})=>{
  //discount - массив с полями как из базы
  setDiscountPrice: ({state, commit}, discount) =>{

    //console.log(discount);

    // глобальная
    if (discount.category == 50){

      // инет минус процент
      if (discount.type == 20) {
        //console.log(discount.sale_percent);

        let price = state.cart_info.price;
        let discount_value = price/100 * discount.sale_percent ;
        discount_value = Math.floor(discount_value);
        let total = price - discount_value;

        let data = {
          id: discount.id,
          value: discount_value,
          total: total
        }

        // console.log(price);
        // console.log(total);
        // console.log(discount_value);

        commit(
          "setActiveDiscount",
          JSON.parse(JSON.stringify(data))
        );

      }
    }
  }



};

const mutations = {

  setOrderResult:(state, data)=>{

    Vue.set(state, "order_result",  data)

  },


  addOrder:(state)=>{

    console.log(state);

  },


  setPaymentType:(state, data)=>{
    Vue.set(state.user_info, "payment_type",  data)
  },

  // data_ar - приходит discount со всеми полями
  setDiscounts:(state, data_ar)=>{

    //console.log(data_ar);

    let discounts = [];

    data_ar.forEach(discount=>{
      discounts.push(discount.id)
    })

    Vue.set(state.discount_info, "discounts", discounts)
  },

  setActiveDiscount:(state, data)=>{
    Vue.set(state.discount_info, "active_discount_id", data.id);

    Vue.set(state.cart_info, "discount", data.value);
    Vue.set(state.cart_info, "total_price", data.total);
  },

  setUserCartInfo:(state, data)=>{

    //console.log(data);
    Vue.set(state, "user_info",  data)

  },

  setOrderPaymentType:(state, data)=>{
    //console.log(data);
    Vue.set(state.order, "payment_type",  data)
  },

  clearDiscount(state){
    state.discount_info.active_discount_id = 0;
    state.cart_info.discount = 0;
    state.cart_info.total_price = state.cart_info.price;
  },

  clearOrder(state){

    state.cart_info = {
      count: 0,
      price: 0,
      discount: 0,
      total_price: 0,

      event:{
        id:0,
        data:{},
        loaded: false
      },

      seats:{
        ids:[],
        data:[],
        info:[],

        ready:false
      }
    };

    console.warn("Не забудь и order очистить!")


  },



  /**
   {
   * free_seat
   * id
   * lay: {
   *    name:
   *    place_id
   *    place{
   *        address
   *        name
   *    }
   * }
   * lay_id
   * prices
   * pushka
   * show {
   *    age
   *    id
   *    name
   *    tag_status_id
   *    time
   * }
   * show_id
   * status
   * timedate
   * timedate_h
   * }
   * @param {event} data
   */
  setEvent:(state, data)=>{

    Vue.set(state.cart_info.event, "loaded", false);

    Vue.set(state.cart_info.event, "id", data.id);


    Vue.set(state.cart_info.event, "data", data);

    Vue.set(state.cart_info.event, "loaded", true);

  },

  setSeatsData:(state, data)=>{

    Vue.set(state.cart_info.seats, "ready", false);
    Vue.set(state.cart_info.seats, "data", data);

  },

  // сгенерировать подсказки - ряд место цена
  createSeatInfo(state, all_seats){

    let seat_ids = [];
    let helpData = [];
    let count = 0;
    let price = 0;


    let selected = [];

    state.cart_info.seats.data.forEach((item)=>{
      let seat = all_seats.find(el=>el.id === item.id)

      seat.caption = seat.row + " ряд, " + seat.chair + " место";

      seat.price = item.price;

      selected.push(seat);
      seat_ids.push(seat.id);

      count++;
      price +=seat.price;
    })



    selected.forEach((item)=>{

      let finded = helpData.find(el=>el.info.id === item.sector_id);

      if(finded === undefined){
        let el =
            {
              info: {
                id:    item.sector_id,
                name:  config.getters.getSectorName(item.sector_id)
              },
              data:      [item]
            }
        helpData.push(el)
      }else{
        finded.data.push(item)
      }
    })

    Vue.set(state.cart_info.seats, "ids",  seat_ids);
    Vue.set(state.cart_info.seats, "info", helpData);

    Vue.set(state.cart_info, "count", count);
    Vue.set(state.cart_info, "price", price);
    Vue.set(state.cart_info, "total_price", price);
    Vue.set(state.cart_info, "discount", 0);
    Vue.set(state.cart_info.seats, "ready", true);

  }

};

export default {
  state,
  getters,
  actions,
  mutations
};
